<template>
  <div id="home" class="home">
    <div class="welcome-screen grid md:grid-cols-3 gap-4 rin">
      <div class="tile px-5 md:py-8 py-5 text-center items-center align-middle flex justify-center"
      :class="{'opacity-50 pointer-events-none': perm.loading}"
        v-for="perm in accesibleViews"
        :key='perm.name'
        @click="goTo(perm)">
        <div>
          <img :src="require('../assets/' + perm.name + '.svg')" alt="" class="mx-auto mb-3">
          {{perm.displayName}}
        </div>
        <span class="is-link" v-if="perm.link">
          <i class="bi bly-small-down bi-rotate-45"></i>
        </span>
      </div>
      <div v-if="isSysAdmin" class="tile px-5 md:py-8 py-5 text-center items-center align-middle flex justify-center">
        <div>
          <div class="text-xs mb-2">Links</div>
          <div class="flex-row">
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1nQiiaapoe4Wqnpc83p1mOpqXkodYkQi7Z-gX-BWKvZ0/edit#gid=0">Booking PLP</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1vN-kq__KMEw2KjIIvcXUoH8c-Up5E_CgekDWfG220MU/edit#gid=757511324">Booking DAZN</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1MzElv0qVVal58Qyeu6GxRDgCm9FBoTWm7Ln4iuzbHPo/edit#gid=1100355988">Booking UZ</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1pRfA9e_zI5KX-2bwPLI4HW_gdixRPqHQ5XmIudm3QCQ/edit?gid=0#gid=0">Booking MTR</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/12zR_YLyDsWLicxLRf_n0nWJTEtx1bZQopOG9kLZbDxk/edit?gid=528160736#gid=528160736">IMG Setup</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/d/1VMwqdC_aS_RpABfcL1fgvn-DzgVw3cOdt_oRd8iAgaI/edit#gid=0">PLP Takers</a>
            <a class="block" target="_blank" href="https://docs.google.com/spreadsheets/u/1/d/1x6p1PwLpUxE7iQgCDROYB6DtA9IbUdF0X8k4DrBj_vc/edit#gid=0">MTR Takers</a>
          </div>
        </div>
        <span class="is-link">
          <i class="bi bly-small-down bi-rotate-45"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { permissions } from '../config';

export default {
  name: 'home',
  data() {
    return {
      source: null,
      showSupportMessage: false,
      permissions,
      isConnectedToVPN: false,
    };
  },

  computed: {
    ...mapState({
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      userFeatures: (state) => state.userAccount.userFeatures,
      userPermissions: (state) => state.userAccount.userPermissions,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
    }),
    accesibleViews() {
      const filtered = [];
      Object.keys(this.permissions).forEach((key) => {
        const hasAccess = this.userFeatures.indexOf(this.permissions[key].code) !== -1;
        if (this.permissions[key].hide) return;
        if (hasAccess || this.userPermissions.isSysAdmin) filtered.push(this.permissions[key]);
      });
      return filtered;
    },
  },

  async mounted() {
    // this.checkVpnConnection();
  },

  methods: {
    ...mapActions({
      fetchClientList: 'app/fetchClientList',
      fetchAccountList: 'app/fetchAccountList',
    }),

    async goTo(perm) {
      const isLink = !!perm.link;
      const hasParams = !!perm.params;
      console.log('xxxxx', perm.VPN);
      if (perm.VPN) {
        perm.loading = true;
        const result = await this.checkVpnConnection()
        console.log('result', result);
        if (!result) {
          this.$message.error('You need to be connected to the VPN to access this page');
          perm.loading = false;
          return;
        }
      }

      if (isLink) {
        window.location.href = perm.link;
        return;
      }
      if (hasParams) {
        this.$router.push({ name: `${perm.name}`, params: perm.params });
        return;
      }
      this.$router.push({ path: `/${perm.name}` });
    },
    async checkVpnConnection() {
      try {
        const response = await fetch('http://10.61.10.207:5174/encoders/', { method: 'HEAD' });
        if (response.type === 'cors') {
          console.log('response', CORS);
          return false;
        }
        console.log('response', response);
        return true;
      } catch (error) {
        console.log('error', error);
        return false;
      }
    },
  },
};
</script>

<style>


.el-loading-spinner svg {
  margin: 0 auto;
}

#home {
  text-align: center;
}
#home .home-logo {
  margin: 0 auto;
  padding: 100px;
  display: block;
}
#home .welcome-screen {
  display: grid;
  margin: 20px;
  padding-top: 0;
}
#home .welcome-screen .tile {
  position: relative;
  background: rgba(102, 113, 141, 0.2);
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  display: grid;
  align-items: center;
  grid-auto-columns: auto;
  grid-auto-flow: column;
}
#home .welcome-screen .tile .ma {
  font-size: 3em;
  color: #CFDFFF;
  margin-right: 10px;
  vertical-align: middle;
}
#home .welcome-screen .tile:hover {
  background: rgba(102, 113, 141, 0.4);
  color: #fff;
}
#home .welcome-screen .tile .is-link {
  position: absolute;
  bottom: 10px;
  right: 0px;
  line-height: 16px;
}
#home .welcome-screen .tile .is-link .ma {
  font-size: 16px;
  color: #66718D;
}
#home .welcome-screen .tile img {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  stroke: #CFDFFF;
}
</style>
