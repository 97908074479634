export default {
  authority: 'https://identity.elastech.net',
  client_id: 'js',
  redirect_uri: 'http://localhost:5003/callback.html',
  response_type: 'id_token token',
  scope: 'openid profile api1',
  post_logout_redirect_uri: 'http://localhost:5003/login',
  apiUrl: 'https://router.elas.tech/api/',
  url: 'https://database.elas.tech/api/',
  awsUrl: 'https://pw003fm5nf.execute-api.eu-central-1.amazonaws.com/dev/',
  routerS3Url: 'https://s3.eu-central-1.amazonaws.com/bdnc-router-images/',
  svcUrl: 'https://svc01.elas.tech:8085/',
  svcUrl2: 'https://svc01.elas.tech:8087/',
  server: 'https://svc01.elas.tech:7001',
  ws: 'wss://svc01.elas.tech:7001',
  mv_control_url: 'https://mvcontrol.elas.tech/',
};

export const permissions = {
  // SERVERS: {
  //   access: true,
  //   name: 'servers',
  //   displayName: 'Servers',
  //   code: 'svr',
  //   hide: false,
  // },
  // // MULTIVIEW: {
  //   access: true,
  //   name: 'multiview',
  //   displayName: 'Multiview',
  //   code: 'mvr',
  // },

  SCHEDULEDEFAULT: {
    access: true,
    name: 'schedule',
    displayName: 'Schedule',
    code: 'sch',
    hide: true,
  },
  SCHEDULEAGENDA: {
    access: true,
    name: 'scheduleagenda',
    displayName: 'Schedule',
    params: {id: 'stats'},
    code: 'sch',
    hide: false,
    loading: false,
  },
  MULTIVIEWER: {
    access: true,
    name: 'multiviewer',
    displayName: 'Multiviewer',
    code: 'mv2',
    hide: false,
  },
  PROBES: {
    access: true,
    name: 'probes',
    displayName: 'Probes',
    link: 'https://probes.elas.tech',
    code: 'prb',
    hide: false,
  },
  ROUTER: {
    access: true,
    name: 'router',
    displayName: 'Router',
    code: 'rtr',
    hide: false,
    loading: false,
  },

  REPORTS: {
    access: true,
    name: 'reports',
    displayName: 'Reports',
    code: 'sys',
    hide: false,
    loading: false,
  },
  ADMIN: {
    access: true,
    name: 'admin',
    displayName: 'Client panel',
    code: 'adm',
    hide: false,
    loading: false,
  },
  
  MAM: {
    access: true,
    name: 'mam',
    link: '/mam/index.html#/',
    displayName: 'MAM',
    code: 'sys',
    hide: false,
    loading: false,
  },
  MVR: {
    access: true,
    name: 'mvr',
    displayName: 'MVR',
    code: 'mrt',
    hide: false,
    loading: false,
  },
  PERMISSIONSSIMPLE: {
    access: true,
    name: 'permissionssimple',
    displayName: 'Sys Admin Panel',
    code: 'sys',
    hide: false,
    loading: false,
  },
  ENCODERS: {
    access: true,
    name: 'encoders',
    link: 'http://10.61.10.207:5174/encoders/',
    displayName: 'ENCODERS',
    code: 'sys',
    hide: false,
    VPN: false,
    loading: false,
  },
  AUDIT: {
    access: true,
    name: 'audit',
    displayName: 'Audit',
    code: 'sys',
    hide: false,
    loading: false,
  },

  // LIVEOPS: {
  //   access: true,
  //   name: 'liveops',
  //   displayName: 'Live Ops',
  //   code: 'sys',
  //   hide: false,
  //   loading: false,
  // },
  // MGW: {
  //   access: true,
  //   name: 'mgw',
  //   displayName: 'MGW',
  //   code: 'sys',
  //   hide: false,
  //   loading: false,
  // },

  // MV: {
  //   access: true,
  //   name: 'mv',
  //   displayName: 'MV',
  //   code: 'sys',
  // },
};

export const statusColors = {
  done: '#343c49',
  failed: 'red',
  status3: 'green',
};

export const scheduleConfig = {
  disableKeyEdit: false,
};

// monitoring config
export const monitoringConfig = {
  heartBeatOffline: 180,
  heartBeatOnline: 60,
};

export const scheduleEventPropertiesNames = {
  fixture: 'Fixture',
  description: 'Description',
  sport: 'Sport',
  property: 'Property',
  lineup: 'Lineup',
  start: 'Kickoff',
  end: 'End',
  input: '',
};
